import AlertDialog from "components/utils/AlertDialog"
import Input from "components/utils/InputField"
import poweredByGoogleLogo from "images/powered_by_google_on_non_white.png"
import Image from "next/image"
import Script from "next/script"
import React, { useCallback, useEffect, useState } from "react"
import { secrets } from "secrets"
import { BasketAddress } from "types/firestore/basket"
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from "use-places-autocomplete"

const convertBasketAddressToString = (address: Partial<BasketAddress>): string => {
  if (!address.address) return ""
  return `${address.address}, ${address.zipCode} ${address.city}`
}

export type AddressInputProps = {
  address: Partial<BasketAddress>
  setAddress: (address: Partial<BasketAddress>) => void
  errors?: string[]
  setErrors?: (errors: string[]) => void
  coordinates?: { lat: number; lng: number }
  isProcessing?: boolean
  setIsProcessing?: (isProcessing: boolean) => void
  /**
   * If true, when the house number is missing it
   * will show an alert dialog prompting
   * the user to put in the house number.  */
  showMissingHouseNumberPopup?: boolean
}

function AddressInput({
  address,
  setAddress,
  errors,
  setErrors,
  coordinates,
  isProcessing = false,
  setIsProcessing,
  showMissingHouseNumberPopup = false,
}: AddressInputProps) {
  const [initialized, setInitialized] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isShowingMissingHouseNumberAlertDialog, setIsShowingMissingHouseNumberAlertDialog] = useState(false)
  const {
    ready,
    suggestions: { status, data },
    setValue,
    value,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      componentRestrictions: { country: "de" },
      language: "de",
      locationRestriction: coordinates && {
        north: coordinates.lat + 0.2,
        south: coordinates.lat - 0.2,
        east: coordinates.lng + 0.2,
        west: coordinates.lng - 0.2,
      },
    },
    initOnMount: true,
    debounce: 300,
    defaultValue: convertBasketAddressToString(address),
    cacheKey: coordinates ? `${coordinates.lat}-${coordinates.lng}` : undefined,
  })

  const handleHouseNumberDialogSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const houseNumber = formData.get("houseNumber") as string

    setAddress({
      ...address,
      address: `${address.address} ${houseNumber}`,
    })

    setIsShowingMissingHouseNumberAlertDialog(false)
  }

  useEffect(() => {
    if (initialized && typeof google !== "undefined") {
      init()
    }
  }, [initialized])

  useEffect(() => {
    const convertedAddress = convertBasketAddressToString(address)

    // Check if the address is different from the value
    if (convertedAddress !== value) {
      setValue(convertedAddress)
      setIsProcessing?.(true)
      processAddress(convertedAddress).then(() => setIsProcessing?.(false))
    }
  }, [address])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const processAddress = useCallback(
    async (addressString: string, placeId?: string) => {
      try {
        setIsProcessing?.(true)
        const geocodeResults = await getGeocode({
          address: placeId ? undefined : addressString,
          componentRestrictions: placeId ? undefined : { country: "de" },
          language: "de",
          placeId,
          bounds: coordinates && {
            north: coordinates.lat + 0.2,
            south: coordinates.lat - 0.2,
            east: coordinates.lng + 0.2,
            west: coordinates.lng - 0.2,
          },
        })

        const result = geocodeResults[0]

        const { lat: latitude, lng: longitude } = getLatLng(result)
        const zipCode = getZipCode(result, false)

        interface PlaceResult {
          address_components: google.maps.GeocoderAddressComponent[]
          vicinity?: string
        }

        const getAddressPart = (components: google.maps.GeocoderAddressComponent[], type: string): string | null => {
          const component = components.find(c => c.types.includes(type))
          return component ? component.long_name : null
        }

        const extractAddressComponents = (result: PlaceResult) => {
          const address_components = result.address_components

          const city =
            getAddressPart(address_components, "locality") ||
            getAddressPart(address_components, "administrative_area_level_3") ||
            getAddressPart(address_components, "administrative_area_level_2") // Fallback for city

          const street = getAddressPart(address_components, "route")
          const number = getAddressPart(address_components, "street_number")
          const locality = getAddressPart(address_components, "sublocality_level_1") || result.vicinity || null

          return {
            city,
            street,
            number,
            locality,
          }
        }

        const addressComponents = { ...extractAddressComponents(result), zip: zipCode }

        // Check if house number is missing
        const missingHouseNumber = !addressComponents.number

        setIsShowingMissingHouseNumberAlertDialog(missingHouseNumber)

        setErrors?.(
          (
            errors?.filter(error => !["zip", "address", "city", "locality", "houseNumber"].includes(error)) ?? []
          ).concat(missingHouseNumber ? ["houseNumber"] : [])
        )

        setAddress({
          city: addressComponents.city || address.city,
          zipCode: addressComponents.zip || address.zipCode,
          address: [addressComponents.street, addressComponents.number].filter(Boolean).join(" ") || address.address,
          locality: addressComponents.locality || address.locality,
          latitude: latitude,
          longitude: longitude,
        })
      } catch (error) {
        console.log("😱 Error: ", error)
      } finally {
        setIsProcessing?.(false)
      }
    },
    [setErrors, setAddress, address, coordinates, setIsProcessing]
  )

  const handleSelect = useCallback(
    (prediction: google.maps.places.AutocompletePrediction) => async () => {
      const { description, place_id } = prediction
      clearSuggestions()
      setIsProcessing?.(true)
      await processAddress(description, place_id)
      setIsProcessing?.(false)
    },
    [clearSuggestions, processAddress, setIsProcessing]
  )

  const handleFocus = () => {
    setIsFocused(true)
    if (address) {
      setValue(convertBasketAddressToString(address))
    }
  }

  const handleBlur = () => {
    // Delay hiding suggestions to allow clicking on them
    setTimeout(() => setIsFocused(false), 200)
  }

  return (
    <>
      <Script
        id="googlePlaces"
        src={`https://maps.googleapis.com/maps/api/js?key=${secrets.google.placesKey}&libraries=places`}
        strategy="lazyOnload"
        onLoad={() => {
          setInitialized(true)
        }}
      />
      <style jsx>{`
        .wrapper {
          background-color: transparent;
          width: 100%;
          position: relative;
          z-index: 10;
        }

        .inputLine {
          justify-self: stretch;
          z-index: 999;
        }

        .suggestionButton {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0.5rem 1rem;
          margin: 0;
          background: var(--dark);
          border: none;
          border-radius: 0;
          outline: none;
          color: white;
          font-size: 1rem;
          height: 100%;

          &:hover,
          &:active,
          &:focus {
            background: white;
            color: var(--dark);
          }
        }

        .suggestionBox {
          position: absolute;
          width: 100%;
          top: 40px;
          left: 0;
          border-radius: 0 0 10px 10px;
          border: 2px solid white;
          z-index: 3;
          display: flex;
          transition: all 1s ease;
          flex-direction: column;
          flex-grow: 1;
          background: var(--dark);
          display: none;
        }

        .suggestionBox.visible {
          display: flex;
        }

        .googleLicence {
          display: flex;

          justify-content: flex-end;
          margin: 0.25rem 0.5rem 0 0.5rem;
        }
      `}</style>
      <div className="wrapper">
        <div className="inputLine">
          <Input
            value={value}
            onChange={e => {
              handleInput(e)
              setErrors?.(errors?.filter(error => error !== "address") ?? [])
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            type="text"
            disabled={!ready || isProcessing}
            name="address"
            autoComplete="street-address"
            danger={errors?.includes("address")}
          >
            {isProcessing ? "Wird verarbeitet..." : "Deine Adresse"}
          </Input>
        </div>
        <div className={`suggestionBox ${isFocused && status === "OK" ? "visible" : ""}`}>
          {status === "OK" && (
            <div className="suggestionList">
              {data.map(suggestion => {
                const {
                  place_id,
                  structured_formatting: { main_text, secondary_text },
                } = suggestion

                return (
                  <button className="suggestionButton" key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                  </button>
                )
              })}{" "}
              <div className="googleLicence">
                <Image width="150" height="20" src={poweredByGoogleLogo} layout="fixed" alt="powered by Google" />
              </div>
            </div>
          )}
        </div>
      </div>

      <AlertDialog
        isOpen={isShowingMissingHouseNumberAlertDialog && showMissingHouseNumberPopup}
        onClose={() => setIsShowingMissingHouseNumberAlertDialog(false)}
        title="Hausnummer fehlt"
        description="Bitte gib deine Hausnummer ein. Eine gültige Hausnummer ist erforderlich, um die Bestellung abzuschließen."
      >
        <form onSubmit={handleHouseNumberDialogSubmit} className="tw-space-y-4">
          <Input placeholder="Hausnummer" name="houseNumber" />
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-items-center">
            <button
              type="submit"
              className="tw-px-4 tw-py-2 tw-rounded-lg tw-text-base"
              style={{
                background: "var(--gradient)",
                color: "var(--gradientText)",
              }}
            >
              Hausnummer bestätigen
            </button>
            <button
              type="button"
              onClick={() => setIsShowingMissingHouseNumberAlertDialog(false)}
              className="tw-px-4 tw-py-2 tw-rounded-lg tw-text-base tw-bg-gray-200 tw-text-gray-800"
            >
              Abbrechen
            </button>
          </div>
        </form>
      </AlertDialog>
    </>
  )
}

// ts-prune-ignore-next
export default AddressInput
