import { getGlobalValue, setGlobalValue, useGlobalValue } from "hooks/localstate/globalVars/useGlobalValue"
import { useEffect } from "react"

interface Address {
  country?: string
  street?: string
  number?: string
  city?: string
  zip?: string
}

// ts-prune-ignore-next
export interface Coordinates {
  latitude: number
  longitude: number
  locality?: string
}

const resolveWithNominatim = async ({ country, street, number, city, zip }: Address) => {
  const response = await fetch(
    `https://nominatim.openstreetmap.org/search?${street ? `street=${street} ${number ? number : ""}&` : ""}${
      city ? `city=${city}&` : ""
    }${zip ? `postalcode=${zip}&` : ""}${country ? `country=${country}&` : ""}format=json&limit=1&addressdetails=1`
  )
  const data = await response.json()
  if (data.length) {
    const addressDetails = data[0]?.address || {}
    const locality =
      addressDetails.village || addressDetails.neighbourhood || addressDetails.quarter || addressDetails.suburb

    const result = {
      latitude: data[0]?.lat,
      longitude: data[0]?.lon,
      locality,
    } as Coordinates

    if (!result.latitude || !result.longitude) {
      // TODO: Find a way to log from production
      return undefined
    }

    console.log("Resolved address with Nominatim")
    return result
  }
  return undefined
}

const resolveWithPhoton = async ({ country, street, number, city, zip }: Address) => {
  const query = `${country ? `${country},` : ""}${zip ? `${zip} ` : ""}${city ? `${city},` : ""}${
    street ? `${street} ${number ? number : ""}` : ""
  }`

  const response = await fetch(`https://photon.komoot.io/api/?q=${query}&limit=1&lang=de`).catch(() => undefined)

  const data = await response?.json()
  const coordinates = data?.features?.[0]?.geometry?.coordinates as [number, number] | undefined

  if (coordinates) {
    const properties = data?.features?.[0]?.properties || {}
    const locality = properties.locality || properties.neighbourhood || properties.suburb || properties.quarter
    const result = {
      latitude: coordinates[1],
      longitude: coordinates[0],
      locality,
    } as Coordinates

    if (!result.latitude || !result.longitude) {
      // TODO: Find a way to log from production
      return undefined
    }

    console.log("Resolved address with Photon")
    return result
  }
  return undefined
}

interface HookAddress {
  street: string
  city?: string
  postalcode?: string
  country?: string
}

function addressToKey(
  street: string,
  postalcode: string | undefined,
  city: string | undefined,
  country: string | undefined
) {
  return `${street}${postalcode}${city}${country || "germany"}`
}

export const useResolveAddress = ({ street, city, postalcode, country }: HookAddress, dontResolve?: boolean) => {
  const key = addressToKey(street, postalcode, city, country)
  const [coordinates] = useGlobalValue<Coordinates>("geocoding", key)

  useEffect(() => {
    // getLatLngFromAddress will trigger setGlobalValue("geocoding", key), if successful
    !dontResolve &&
      getResolveAddress({
        street,
        city,
        postalcode,
        country,
      })
  }, [key, !!dontResolve])

  return coordinates
}

export const getResolveAddress = async ({ street, city, postalcode, country }: HookAddress) => {
  const key = addressToKey(street, postalcode, city, country)

  const resolveAddress = async () => {
    const streetOnly = street.match(/^[^0-9]+/gm)?.[0]
    const streetNumber = street.match(/([0-9]+) *$/gm)?.[0]
    const address: Address = {
      country: country,
      street: streetOnly,
      number: streetNumber,
      city: city,
      zip: postalcode,
    }

    const nominatimResult = await resolveWithNominatim(address)
    if (nominatimResult) {
      setGlobalValue("geocoding", key, nominatimResult)
      return nominatimResult
    }

    const photonResult = await resolveWithPhoton(address)
    if (photonResult) {
      setGlobalValue("geocoding", key, photonResult)
      return photonResult
    }

    // Remove this promise, to retry later
    setGlobalValue("geocodingMutex", key, undefined)
    return undefined
  }

  if (!getGlobalValue<Promise<Coordinates | undefined>>("geocodingMutex", key)) {
    setGlobalValue<Promise<Coordinates | undefined>>("geocodingMutex", key, resolveAddress())
  }
  const coordinatesPromise = getGlobalValue<Promise<Coordinates | undefined>>("geocodingMutex", key)

  const result = await coordinatesPromise

  result || console.log("Failed to resolve address. Using fallback.")
  return result
}
